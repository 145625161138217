import { useField } from "@unform/core";
import parse from "html-react-parser";
import { useEffect, useRef, useState } from "react";

function Radio({
  name,
  label,
  options,
  id,
  description,
  number,
  required,
  ...rest
}) {
  const inputRefs = useRef([]);
  const { fieldName, registerField, defaultValue = "", error } = useField(name);
  const [selectedOption, setSelectedOption] = useState(null);

  const radioOptions = options.map((option, index) => {
    return {
      id: index,
      value: option,
      label: option,
    };
  });

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs,
      getValue: (refs) => {
        return {
          value: refs.current.find((input) => input?.checked)?.value,
          id: String(id),
          type: "radio",
        };
      },
      setValue: (refs, id) => {
        const inputRef = refs.current.find((ref) => ref.id === id);
        if (inputRef) {
          inputRef.checked = true;
          setSelectedOption(id);
        }
      },
      clearValue: (refs) => {
        const inputRef = refs.current.find((ref) => ref.checked === true);
        if (inputRef) {
          inputRef.checked = false;
          setSelectedOption(null);
        }
      },
    });
  }, [fieldName, id, registerField]);

  const handleOptionChange = (optionId) => {
    setSelectedOption(optionId);
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column" }}
      className="reportRadioButtons inputGroup"
    >
      <span className="inputGroup__label">
        {number + ". " + label + ":"}
        {required && <span style={{ color: "red" }}> *</span>}
      </span>

      {description &&
        (description.html ? (
          parse(description.html)
        ) : (
          <p className="inputGroup__description">{description.plaintext}</p>
        ))}
      {radioOptions.map((option, index) => (
        <label
          key={option.id}
          className={selectedOption === option.id ? "selected" : ""}
        >
          <input
            type="radio"
            ref={(ref) => {
              inputRefs.current[index] = ref;
            }}
            id={option.id}
            name={name}
            defaultChecked={defaultValue.includes(option.id)}
            value={option.value}
            onChange={() => handleOptionChange(option.id)}
            {...rest}
          />
          <span>{option.label}</span>
        </label>
      ))}
      {error && (
        <span style={{ paddingTop: "16px", color: "red" }}>{error}</span>
      )}
    </div>
  );
}

export default Radio;
