import { Loader } from "@googlemaps/js-api-loader";
import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as RightArrow } from "../../assets/right-arrow.svg";
import { HomePageContext } from "../../contexts/HomePageContext";
import { Map, MapDescription } from "./style";

export default function MapCard({ data, withDescription }) {
  const { setPart } = useContext(HomePageContext);

  const [map, setMap] = useState(null);

  const { map: mapData } = data;
  const { coordinates } = mapData;

  useEffect(() => {
    const loader = new Loader({
      apiKey: "AIzaSyBCaDDp6_Isop8DM5ArY8-mxiLcxet-PTg",
      version: "weekly",
      libraries: ["places"],
    });

    loader
      .load()
      .then(() => {
        const newMap = new window.google.maps.Map(
          document.getElementById("map"),
          {
            center: { lat: coordinates.lat, lng: coordinates.lng },
            zoom: 16,
            disableDefaultUI: true,
          }
        );

        setMap(newMap);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [coordinates.lat, coordinates.lng]);

  useEffect(() => {
    if (map) {
        new window.google.maps.Marker({
        position: { lat: coordinates.lat, lng: coordinates.lng },
        map,
      });
    }
  }, [coordinates.lat, coordinates.lng, map]);

  return (
    <>
      <Map id="map"/>
      {withDescription && (
        <MapDescription>
          <button
            onClick={() => {
              setPart("select-team");
            }}
          >
            <div className="card-body">
              <div className="card-title">{data.mapCard.title}</div>
              <div className="card-text">
                <strong>{data.mapCard.date}</strong>
                <br />
                {data.mapCard.description}
              </div>
            </div>
            <div className="card-footer">
              {/* <Button
                onClick={() => {
                  setPart("select-team");
                }}
              > */}
                <RightArrow />
              {/* </Button> */}
            </div>
          </button>
        </MapDescription>
      )}
    </>
  );
}
