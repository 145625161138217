import parse from "html-react-parser";
import { useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useNavigate } from "react-router-dom";
import RadioButton from "../../components/RadioButton";
import TeamCard from "../../components/TeamCard";
import { StyledAtLocationPage } from "./style";

export default function AtLocationPage({ data }) {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState("yes");
  const [progress, setProgress] = useState(
    Number((((index + 2) / data.content.length) * 100).toFixed(1))
  );

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    setProgress(Number(((index + 1) / data.content.length) * 100).toFixed(1));
  }, [data.content.length, index]);

  return (
    <StyledAtLocationPage>
      <main className="container">
        <TeamCard data={data.header} />
        <div className="main-header">
          <div>
            <h1>{data.content[index].title}</h1>
            <p>{data.content[index].description}</p>
          </div>
        </div>
        <div className="content">
          {data.content[index].question && (
            <>
              <RadioButton
                question={data.content[index].question}
                selectedOption={selectedOption}
                onChange={handleOptionChange}
                labels={data.answers}
              />
              {selectedOption === "no" &&
                (index === 2 ? (
                  <div className="error-message">
                    {parse(data.content[index].errorMessage.html)}
                  </div>
                ) : (
                  <div className="error-message">{data.errorMessage}</div>
                ))}
            </>
          )}
          <div className="footer">
            <button
              type="button"
              className={
                selectedOption === "yes" ? "confirm-button" : "disabled-button"
              }
              onClick={() => {
                if (index < data.content.length - 1) {
                  setIndex(index + 1);
                } else {
                  navigate("/during-the-work");
                }
              }}
              disabled={selectedOption === "no"}
            >
              {data.content[index].buttonLabel.toUpperCase()}
            </button>
          </div>
        </div>
        <div className="progressWrapper">
          <ProgressBar now={progress} />
          <p className="progressLabel">
            {index + 1} / {data.content.length}
          </p>
        </div>
      </main>
    </StyledAtLocationPage>
  );
}
