import styled from "styled-components";

export const StyledAtLocationPage = styled.div`
  main.container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    padding-bottom: 24px;
    min-height: 100vh;
    padding-inline: var(--bs-gutter-x);

    .main-header {
      > div {
        margin-top: 64px;
        margin-bottom: 46px;

        h1 {
          font-weight: 700;
          font-size: 32px;
          color: #000000;
        }

        p {
          font-weight: 400;
          font-size: 20px;
          color: #000000;
          margin-bottom: none;
        }
      }
    }

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .error-message {
        width: 100%;
        padding: 12px;
        margin-top: 12px;
        border: 2px solid #FF0000;
        background: rgba(255, 0, 0, 0.12);

        font-size: 18px;
        font-weight: 400;
      }
    
      }

      .footer {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        padding-top: 24px;

        .confirm-button {
          padding: 16px 24px;
          background-color: #77ffba;
          border-radius: 8px;
          border: none;
          color: #000000;
          font-size: 16px;
          font-weight: 700;
        }

        .disabled-button {
          padding: 16px 24px;
          background-color: #d7d7d7;
          border-radius: 8px;
          border: none;
          color: #000000;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }

    .progressWrapper {
      display: flex;
      width: 100%;
      flex-direction: row;
      margin-top: auto;
      padding-block: 32px 72px;
      align-items: center;
      color: #000000;
      font-weight: 700;

      .progress-bar {
        background-color: #77FFBA;
      }

      .progress {
        width: 92%;
      }

      .progressLabel {
        width: 8%;
        min-width: 62px;
        margin-bottom: 0;
        text-align: right;
      }
    }
`;
