import React, { useContext, useRef } from "react";
import { Spinner } from "react-bootstrap";
import { ReactComponent as Camera } from "../../assets/camera.svg";
import { DuringWorkPageContext } from "../../contexts/DuringWorkPageContext";

export default function UploadImageComponent({
  data,
  handleSubmit,
  isLoading,
}) {
  const { imageObject, setImageObject } = useContext(DuringWorkPageContext);

  const handleFileInput = useRef(null);

  const handleClick = () => {
    handleFileInput.current.click();
  };

  const handleImageChange = (event) => {
    setImageObject({
      imagePreview: event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : undefined,
      imageFile: event.target.files[0],
    });
  };

  return (
    <>
      <button className="photo-button" onClick={handleClick}>
        <Camera />
        {data.label}
        <input
          style={{ display: "none" }}
          type="file"
          accept="image/*"
          capture="environment"
          ref={handleFileInput}
          onChange={handleImageChange}
        />
      </button>
      {imageObject && imageObject.imageFile && imageObject.imagePreview && (
        <div className="uploadContainer">
          <figure className="uploadedImage">
            <img alt="uploaded-file" src={imageObject.imagePreview} />
          </figure>
          <button
            onClick={() => handleSubmit()}
            className="send-button"
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : data.submit}
          </button>
        </div>
      )}
    </>
  );
}
