import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomePageContextProvider } from "./contexts/HomePageContext";
import AtLocationPage from "./pages/atLocation";
import HomePage from "./pages/home";
import "./styles/global.css";

import { DuringWorkPageContextProvider } from "./contexts/DuringWorkPageContext";
import data from "./data/data.json";
import DuringTheWorkPage from "./pages/duringTheWork";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <HomePageContextProvider>
              <HomePage data={data.homePage} />
            </HomePageContextProvider>
          }
        />
        <Route
          path="/at-location"
          element={<AtLocationPage data={data.atLocationPage} />}
        />
        <Route
          path="/during-the-work"
          element={
            <DuringWorkPageContextProvider>
              <DuringTheWorkPage data={data.duringWorkPage} />
            </DuringWorkPageContextProvider>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
