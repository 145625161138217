import { useState, createContext } from "react";

export const HomePageContext = createContext();

export function HomePageContextProvider(props) {
  const [part, setPart] = useState("overview");
  const [error, setError] = useState(false);

  return (
    <HomePageContext.Provider value={{ part, setPart, error, setError }}>
      {props.children}
    </HomePageContext.Provider>
  );
}
