import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import MapCard from "../../../components/MapCard";
import TeamCard from "../../../components/TeamCard";

export default function EquipmentInfo({ data }) {
  const navigate = useNavigate();

  return (
    <>
      <TeamCard data={data.header} />
      <div className="main-header">
        <div>
          <h1>{data.title}</h1>
          <p>{data.description}</p>
        </div>
      </div>
      <div className="content">
        <MapCard data={data} />
        <div className="subtitle">
          {data.adress.html ? parse(data.adress.html) : data.adress.plaintext}
        </div>
        <div className="footer">
          <button
            type="button"
            className="confirm-button"
            onClick={() => navigate("/at-location")}
          >
            {data.button.label}
          </button>
        </div>
      </div>
    </>
  );
}
