import MapCard from "../../../components/MapCard";

export default function Overview({ data }) {
  return (
    <>
      <div className="main-header">
        <div>
          <h1>{data.title}</h1>
        </div>
      </div>
      <div className="content">
        <MapCard data={data} withDescription={true} />
      </div>
    </>
  );
}
