import { useField } from "@unform/core";
import parse from "html-react-parser";
import { useEffect, useRef } from "react";

function Input({
  name,
  type,
  label,
  value,
  description,
  number,
  required,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const defaultInputValue = value || defaultValue;

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return {
          value: ref.current.value,
          id: ref.current.id,
          type: type,
        };
      },
      setValue: (ref, newValue) => {
        ref.current.value = newValue;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField, type]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column" }}
      className={name === "date" ? "inputGroup inputGroup--date" : "inputGroup"}
    >
      <label className="inputGroup__label" htmlFor={fieldName}>
        {number + ". " + label + ":"}
        {required && <span style={{ color: "red" }}> *</span>}
      </label>
      {description &&
        (description.html ? (
          parse(description.html)
        ) : (
          <p className="inputGroup__description">{description.plaintext}</p>
        ))}

      <input
        type={type || "text"}
        id={fieldName}
        ref={inputRef}
        defaultValue={defaultInputValue}
        {...rest}
      />

      {error && (
        <span style={{ paddingTop: "16px", color: "red" }}>{error}</span>
      )}
    </div>
  );
}

export default Input;
