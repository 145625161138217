import styled from "styled-components";

export const StyledRadioButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  .question {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  .answers {
    display: flex;
    flex-direction: column;
    gap: 10px;

    label {
      position: relative;
      display: flex;
      flex-direction: row;
      font-weight: 700;
      text-transform: uppercase;
      vertical-align: middle;
    }

    label::before {
      position: relative;
      content: "";
      margin-right: 12px;
      border: 2px solid #000;
      border-radius: 50%;
      width: 28px;
      height: 28px;
    }

    label::after {
      position: absolute;
      content: "";
      top: calc(12px - 7px);
      left: calc(12px - 7px);
      border-radius: 50%;
      width: 18px;
      height: 18px;
      background-color: #fff;
    }

    label.selected.valid::after {
      background-color: #77ffba;
    }
    label.selected::after {
      background-color: #f00;
    }

    input {
      position: absolute;
      bottom: 12px;
      left: 12px;
      width: 1px;
      height: 1px;
      opacity: 0;
    }
  }
`;
