import { useContext } from "react";
import TeamCard from "../../components/TeamCard";
import { DuringWorkPageContext } from "../../contexts/DuringWorkPageContext";
import Confirmation from "./content/Confirmation";
import Report from "./content/Report";
import { StyledDuringWorkPage } from "./style";

export default function DuringTheWorkPage({ data }) {
  const { part } = useContext(DuringWorkPageContext);
  const renderPart = (part) => {
    switch (part) {
      case "confirmation":
        return <Confirmation data={data.confirmation} email={data.emailSettings}/>;
      case "report":
        return <Report data={data.report} email={data.emailSettings}/>;
      default:
    }
  };

  return (
    <StyledDuringWorkPage>
      <main className="container">
        <TeamCard data={data.header}/>
        {renderPart(part)}
      </main>
    </StyledDuringWorkPage>
  );
}
