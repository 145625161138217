export default function Email(content) {

  const user =
    localStorage.getItem("user") !== "null" ? localStorage.getItem("user") : "";
  const event =
    localStorage.getItem("event") !== "null" ? localStorage.getItem("event") : "";
  const htmlEmail = `
  <html lang="en">
    The following form has been submitted:
    <br/>
    <br/>
    <strong>Name:</strong> ${user}<br/>
    <strong>Event:</strong> ${event}<br/>
    ${content ? content : ""}
  </html>
  `;

  return htmlEmail;
}
