import { StyledRadioButton } from "./style";

export default function RadioButton({
  selectedOption,
  onChange,
  question,
  labels,
}) {
  return (
    <StyledRadioButton>
      <div className="question">{question}</div>
      <div className="answers">
        <label
          className={
            selectedOption === 'yes' ? 'selected valid' : ''
          }
        >
          <input
            type="radio"
            value="yes"
            checked={selectedOption === "yes"}
            onChange={onChange}
          />
          {labels.yes}
        </label>
        <label
          className={
            selectedOption === 'no' ? 'selected' : ''
          }
        >
          <input
            type="radio"
            value="no"
            checked={selectedOption === "no"}
            onChange={onChange}
          />
          {labels.no}
        </label>
      </div>
    </StyledRadioButton>
  );
}
