import axios from "axios";
import { useContext, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import * as Yup from "yup";
import Email from "../../../components/Email";
import Input from "../../../components/Form/Inputs/Input";
import Radio from "../../../components/Form/Inputs/Radio";
import Textarea from "../../../components/Form/Inputs/TextArea";
import { DuringWorkPageContext } from "../../../contexts/DuringWorkPageContext";
import { Form } from "../style";

export default function Report({ data, email }) {
  const { submitErrorMessage, fieldErrorMessage } = data;
  const { setPart } = useContext(DuringWorkPageContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = async (data) => {
    setIsLoading(true);

    const formData = {
      eventType: data.eventType.value,
      type: data.type.value,
      date: data.date.value,
      areaVessel: data.areaVessel.value,
      description: data.description.value.replace(/\n/g, '<br/>'),
      cause: data.cause.value.replace(/\n/g, '<br/>'),
      measures: data.measures.value.replace(/\n/g, '<br/>'),
      additionalInfo: data.additionalInfo.value.replace(/\n/g, '<br/>'),
      signature: data.signature.value,
    };

    try {
      const schema = Yup.object().shape({
        eventType: Yup.string().required(fieldErrorMessage),
        type: Yup.string().required(fieldErrorMessage),
        date: Yup.string().required(fieldErrorMessage),
        areaVessel: Yup.string().required(fieldErrorMessage),
        description: Yup.string().required(fieldErrorMessage),
        cause: Yup.string().required(fieldErrorMessage),
        measures: Yup.string().required(fieldErrorMessage),
      });

      await schema.validate(formData, { abortEarly: false });

      const body = `
        <div>
          <strong>Event Type:</strong> ${formData.eventType}<br />
          <strong>Type:</strong> ${formData.type}<br />
          <strong>Date:</strong> ${formData.date}<br />
          <strong>Vessel/Area</strong>: ${formData.areaVessel}<br />  
          <strong>Description:</strong> ${formData.description}<br />
          <strong>Cause:</strong> ${formData.cause}<br />
          <strong>Measures:</strong> ${formData.measures}<br />
          <strong>Additional info:</strong> ${formData.additionalInfo}<br />
          <strong>Signature:</strong> ${formData.signature}<br />
        </div>
      `;

      const emailHtml = Email(body);
      const requestData = new FormData();

      requestData.append("to", email.receiver);
      requestData.append("subject", email.subject);
      requestData.append("html", emailHtml);

      await axios
        .post("https://review.api.nofo.k8s.seeds.no/send", requestData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setIsLoading(false);
          setPart("confirmation");
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach((error) => {
          if (error.path) {
            errorMessages[error?.path] = error.message;
          }
        });
        formRef.current?.setErrors(errorMessages);
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setError(true);
        formRef.current?.setErrors({});
      }

      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="main-header">
        <div>
          <h1>{data.title}</h1>
        </div>
      </div>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Radio
          name="eventType"
          number={1}
          label={data.questions[0].label}
          options={data.questions[0].options}
          required
        />
        <Radio
          name="type"
          number={2}
          label={data.questions[1].label}
          options={data.questions[1].options}
          description={data.questions[1].description}
          required
        />
        <Input
          name="date"
          number={3}
          id="date"
          type="date"
          label={data.questions[2].label}
          description={data.questions[2].description}
          required
        />
        <Input
          name="areaVessel"
          number={4}
          id="areaVessel"
          label={data.questions[3].label}
          description={data.questions[3].description}
          required
        />
        <Textarea
          name="description"
          number={5}
          id="description"
          label={data.questions[4].label}
          description={data.questions[4].description}
          required
        />
        <Textarea
          name="cause"
          number={6}
          id="cause"
          label={data.questions[5].label}
          description={data.questions[5].description}
          required
        />
        <Textarea
          name="measures"
          number={7}
          id="measures"
          label={data.questions[6].label}
          description={data.questions[6].description}
          required
        />
        <Textarea
          name="additionalInfo"
          number={8}
          id="additionalInfo"
          label={data.questions[7].label}
          description={data.questions[7].description}
        />
        <Input
          name="signature"
          number={9}
          id="signature"
          label={data.questions[8].label}
          description={data.questions[8].description}
        />
        <div className="list-buttons">
          <button type="submit" className="form-button" disabled={isLoading}>
            {isLoading ? (
              <Spinner />
            ) : (
              data.button["submit"].label.toUpperCase()
            )}
          </button>
          {error && (
            <span style={{ color: "red", padding: "16px" }}>
              {submitErrorMessage}
            </span>
          )}
        </div>
      </Form>
    </>
  );
}
