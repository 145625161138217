import { useContext, useEffect, useState } from "react";
import TeamCard from "../../../components/TeamCard";
import { HomePageContext } from "../../../contexts/HomePageContext";

export default function SelectFieldRole({ data }) {
  const { setPart, setError } = useContext(HomePageContext);
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    if (selectedOption) {
      if (selectedOption === "Strandrydder?") {
        setError(false);
        setPart("equipment-info");
      } else {
        setError(true);
      }
    }
  }, [selectedOption, setError, setPart]);

  return (
    <>
      <TeamCard data={data.header} />
      <div className="main-header">
        <div>
          <h1>{data.title}</h1>
        </div>
      </div>
      <div className="content">
        <div className="list-buttons list-buttons--small">
          {data.buttons.map((label) => {
            return (
              <button
                key={label}
                className="role-button"
                type="button"
                onClick={() => {
                  setSelectedOption(label);
                }}
              >
                {label.toUpperCase()}
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
}
