import styled from 'styled-components';
import { Card } from 'react-bootstrap';

export const Map = styled(Card)`
  width: 100%;
  height: 400px;
  border: 4px solid #000000;
  border-radius: 19px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`;

export const MapDescription = styled(Card)`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 4px solid #000000;
  border-radius: 19px;
  overflow: hidden;
  background-color: #000;
  color: #fcfcfc;
  position: relative;
  top: -30px;
  
  button {
    color: inherit;
    text-decoration: none;
    min-width: 100%;
    background-color: #000;
    border: none;
    border-radius: inherit;
    overflow: hidden;
    text-align: left;
  }

  .card-body {
    padding: 24px;
  }

  .card-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 24px;
  }

  .card-text {
    font-size: 16px;
    font-weight: 500;
  }

  .card-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border-top: none;
    margin-left: auto; /* Align content at the end of the row */
    padding: 24px;
  }
`;

export const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;
