import styled from "styled-components";

export const StyledHomePage = styled.div`
  main.container {
    width: 100%;
    padding-bottom: 48px;
    padding-inline: var(--bs-gutter-x);

    .main-header {
      > div {
        margin-top: 64px;
        margin-bottom: 46px;

        h1 {
          font-weight: 700;
          font-size: 32px;
          color: #000000;
        }

        p {
          font-weight: 400;
          font-size: 20px;
          color: #000000;
          margin-bottom: 0;
        }
      }
    }

    .error-message {
      width: 100%;
      padding: 12px;
      margin-top: 12px;
      border: 2px solid #ff0000;
      background: rgba(255, 0, 0, 0.12);

      font-size: 18px;
      font-weight: 400;
    }

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .list-buttons {
        width: 100%;
        max-width: 576px;
        margin-inline: auto;
        display: flex;
        flex-direction: column;
        gap: 16px; /* Add spacing between buttons */

        .role-button {
          width: 100%;
          padding: 22px 12px;
          background-color: #000000;
          border-radius: 18px;
          border-color: transparent;
          color: #ffffff;
          font-size: 40px;
          line-height: 50px;
          font-weight: 700;
        }
      }

      .list-buttons.list-buttons--small {
        .role-button {
          padding: 12px;
          font-size: 24px;
          line-height: 36px;
        }
      }

      .subtitle {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-top: 10px;
      }

      .footer {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-top: 24px;

        .confirm-button {
          padding: 16px 24px 16px 24px;
          background-color: #77ffba;
          border-radius: 8px;
          border: none;
          color: #000000;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  }
`;
