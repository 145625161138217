import { useField } from "@unform/core";
import parse from "html-react-parser";
import { useEffect, useRef } from "react";

function Textarea({
  name,
  label,
  rows = 5,
  cols,
  description,
  number,
  required,
  ...rest
}) {
  const textareaRef = useRef(null);
  const { fieldName, defaultValue = "", registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef,
      getValue: (ref) => {
        return {
          value: ref.current.value,
          id: ref.current.id,
          type: "textarea",
        };
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column" }}
      className="inputGroup"
    >
      <label className="inputGroup__label" htmlFor={fieldName}>
        {number + ". " + label + ":"}
        {required && <span style={{ color: "red" }}> *</span>}
      </label>
      {description &&
        (description.html ? (
          parse(description.html)
        ) : (
          <p className="inputGroup__description">{description.plaintext}</p>
        ))}

      <textarea
        ref={textareaRef}
        id={fieldName}
        defaultValue={defaultValue}
        rows={rows}
        cols={cols}
        {...rest}
      />

      {error && (
        <span style={{ paddingTop: "16px", color: "red" }}>{error}</span>
      )}
    </div>
  );
}

export default Textarea;
