import axios from "axios";
import parse from "html-react-parser";
import { useContext, useState } from "react";
import Email from "../../../components/Email";
import UploadImageComponent from "../../../components/UploadImage";
import { DuringWorkPageContext } from "../../../contexts/DuringWorkPageContext";

export default function Confirmation({ data, email }) {
  const { setPart, imageObject, setImageObject } = useContext(
    DuringWorkPageContext
  );
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitImage = async () => {
    setIsLoading(true);
    const emailHtml = Email();
    const requestData = new FormData();

    requestData.append("to", email.receiver);
    requestData.append("subject", email.subject);
    requestData.append("html", emailHtml);
    requestData.append("attachments", imageObject.imageFile);

    await axios
      .post("https://review.api.nofo.k8s.seeds.no/send", requestData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setError(false);
        setIsLoading(false);
        setImageObject(undefined);
      })
      .catch((error) => {
        setError(true);
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="main-header">
        <div>
          <h1>{data.title}</h1>
          <p>
            <strong>{data.description}</strong>
          </p>
          {data.text.html ? parse(data.text.html) : <p>{data.plaintext}</p>}
        </div>
      </div>
      <div className="content">
        <div className="list-buttons">
          <button
            className="form-button"
            type="button"
            onClick={() => {
              setPart("report");
            }}
          >
            {data.buttons["report"].label}
          </button>
          <div className="photoSubmitWrapper">
            <div className="photo-button-description">
              {data.buttons["photo"].description}
            </div>
            <UploadImageComponent
              data={data.buttons["photo"]}
              handleSubmit={handleSubmitImage}
              isLoading={isLoading}
            />
          </div>
          {error && (
            <span style={{ color: "red", padding: "16px" }}>
              {data.submitErrorMessage}
            </span>
          )}
          <button className="info-button" type="button">
            {data.buttons["info"].label}
          </button>
        </div>
      </div>
    </>
  );
}
