import { useContext } from "react";
import { StyledHomePage } from "./style";

import { HomePageContext } from "../../contexts/HomePageContext";
import EquipmentInfo from "./content/EquipmentInfo";
import Overview from "./content/Overview";
import SelectFieldRole from "./content/SelectFieldRole";
import SelectTeam from "./content/SelectTeam";

export default function HomePage({ data }) {
  const { part, error } = useContext(HomePageContext);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const user = urlParams.get("user");
  const event = urlParams.get("event");

  localStorage.setItem("user", user);
  localStorage.setItem("event", event);

  const renderPart = (part) => {
    switch (part) {
      case "overview":
        return <Overview data={data.overview} />;
      case "select-team":
        return <SelectTeam data={data.selectTeam} />;
      case "field-role":
        return <SelectFieldRole data={data.selectFieldRole} />;
      case "equipment-info":
        return <EquipmentInfo data={data.equipmentInfo} />;
      default:
    }
  };

  return (
    <>
      <StyledHomePage>
        <main className="container">
          {renderPart(part)}
          {error && (
            <div style={{ marginTop: "16px" }}>
              <div className="error-message">{data.errorMessage}</div>
            </div>
          )}
        </main>
      </StyledHomePage>
    </>
  );
}
