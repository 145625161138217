import styled from "styled-components";

export const StyledTeamCard = styled.div`
  .team-card {
    width: 100%;
    padding-top: 32px;
    display: flex;
    flex-direction: row;

    .team {
      width: 15%;
      min-width: 71px;
      background-color: #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px;

      color: #ffffff;
      font-weight: 700;
      font-size: 18px;
    }

    .operation-manager {
      width: 100%;
      display: flex;
      align-items: center;
      background-color: #d9d9d9a3;
      padding: 8px;

      font-size: 18px;
      font-weight: 700;
      color: #000000;
    }
  }
`;
