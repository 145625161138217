import { StyledTeamCard } from "./style";

export default function TeamCard({data}) {
  return (
    <StyledTeamCard style={{width: "100%"}}>
      <div className="team-card">
        <div className="team">{data.team.toUpperCase()}</div>
        <div className="operation-manager">{data.role}</div>
      </div>
    </StyledTeamCard>
  );
}
