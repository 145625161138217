import { createContext, useState } from "react";

export const DuringWorkPageContext = createContext();

export function DuringWorkPageContextProvider(props) {
  const [part, setPart] = useState("confirmation");
  const [imageObject, setImageObject] = useState(null);

  return (
    <DuringWorkPageContext.Provider
      value={{ part, setPart, imageObject, setImageObject }}
    >
      {props.children}
    </DuringWorkPageContext.Provider>
  );
}
