import { Form as Unform } from "@unform/web";
import styled from "styled-components";
import calendar from "../../assets/calendar.svg";

export const StyledDuringWorkPage = styled.div`
  main.container {
    width: 100%;
    padding-bottom: 48px;
    padding-inline: var(--bs-gutter-x);

    .main-header {
      > div {
        margin-top: 64px;
        margin-bottom: 46px;

        h1 {
          font-weight: 700;
          margin-bottom: 12px;
          font-size: 32px;
          color: #000000;
        }

        p {
          font-weight: 400;
          font-size: 20px;
          color: #000000;
          margin-bottom: none;
        }
      }
    }

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .list-buttons {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px; /* Add spacing between buttons */

        .form-button {
          padding: 16px 24px 16px 24px;
          background-color: #77ffba;
          border-radius: 18px;
          border: none;
          color: #000000;
          font-size: 16px;
          font-weight: 700;
          text-align: left;
        }

        .photoSubmitWrapper {
          background-color: #baffdc;
          border-radius: 18px;

          .photo-button-description {
            padding: 16px 24px;
            background-color: #baffdc;
            border-radius: 18px;
            border: none;
            color: #000000;
            font-size: 16px;
            font-weight: 400;
            height: calc(100% + 62px);
          }

          .photo-button {
            display: flex;
            position: relative;
            width: 100%;
            gap: 8px;
            align-items: center;
            justify-content: flex-start;
            padding: 16px 24px;
            background-color: #77ffba;
            border-radius: 18px;
            border: none;
            color: #000000;
            font-size: 16px;
            font-weight: 700;
          }
        }

        .send-button {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: flex-end;
          padding: 16px 24px 16px 24px;
          background-color: #77ffba;
          border-radius: 18px;
          border: none;
          color: #000000;
          font-size: 16px;
          font-weight: 700;
        }

        .info-button {
          padding: 16px 24px 16px 24px;
          background-color: #ffe177;
          border-radius: 18px;
          border: none;
          color: #000000;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
    
    .inputGroup {
      margin-bottom: 48px;

      .inputGroup__label {
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
        margin-bottom: 12px;
      }

      input:not([type="radio"]), textarea {
        padding: 8px 12px;
        border: 1px solid #000;
        background-color: #f4f4f4;
        &:placeholder {
          color: initial;
        }
      }
      input[type="date"] {
        @media (min-width: 767px) {
          background-image: url("${calendar}");
          background-repeat: no-repeat;
          background-position: center right 15px;
          background-size: 15px;
          min-height: 42px;
          &::-webkit-inner-spin-button,
          &::-webkit-calendar-picker-indicator {
            opacity:0;
          }
          @supports (-webkit-touch-callout: none) {
            padding-right: 42px;
          }
        }
      }
    }

    .inputGroup--date {
        width: 50%;
        max-width: 198px;
    }

    .reportRadioButtons {
      display: flex;

      label {
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        padding-block: 16px;
        margin-bottom: 0;
      }
      
      label:not(:last-child) {
        border-bottom: 1px solid #000;
      }

      label::before {
        position: relative;
        flex-shrink: 0;
        content: "";
        margin-left: auto;
        border: 2px solid #000;
        border-radius: 50%;
        width: 28px;
        height: 28px;
      }

      label::after {
        position: absolute;
        content: "";
        top: calc(30px - 9px);
        right: calc(12px - 7px);
        border-radius: 50%;
        width: 18px;
        height: 18px;
        background-color: #fff;
      }

      label.selected::after {
        background-color: #77ffba;
      }

      input {
        position: absolute;
        top: 30px;
        right: 12px;
        width: 1px;
        height: 1px;
        opacity: 0;
      }
    }

    .uploadContainer {
      .uploadedImage {
        width: 100%;
        height: 100%;
        max-width: 648px;
        max-height: 768px;
        margin-inline: auto;
        margin-bottom: 0;
        padding: 16px 12px;
  
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border: 2px solid #000000;
          background-color: #fff;
          border-radius: 8px;
          padding: 8px;
        }
      }
    }
  }
`;

export const Form = styled(Unform)`
  margin-bottom: 24px;
  .region-label {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  .list-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px; /* Add spacing between buttons */

    .form-button {
      padding: 16px 24px 16px 24px;
      background-color: #77ffba;
      border-radius: 8px;
      border: none;
      color: #000000;
      font-size: 16px;
      font-weight: 700;
    }
  }
`;
